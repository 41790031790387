import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { useMediaQuery } from "react-responsive"

import Row from "../grid/row"
import Col from "../grid/column"
import Spacer from "../spacer"
import Container from "../container"
import SinglePublication from "./single-publication"
import Carousel from "../carousel"

import themeMQ from "../../theme/sections/mq"

import SliderArrowLeft from "../../assets/images/slider-arrow-left.svg"
import SliderArrowRight from "../../assets/images/slider-arrow-right.svg"

const ArrowLeftEl = styled.div`
  background: url(${SliderArrowLeft});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(80)};
    height: ${rem(80)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(50)};
    height: ${rem(50)};
  }
`

const ArrowRightEl = styled.div`
  background: url(${SliderArrowRight});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(80)};
    height: ${rem(80)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(50)};
    height: ${rem(50)};
  }
`

const Section = styled.section`
  background: ${(props) => props.theme.colorBlack};
  color: #fff;
`

const PublicationsSection = styled.div`
  border-top: 1px solid ${(props) => props.theme.colorPaleGrey};

  &:last-of-type {
    border-bottom: 1px solid ${(props) => props.theme.colorPaleGrey};
  }
`

const Heading = styled.div`
  display: flex;
  align-items: center;

  .styled-label {
    margin-right: ${rem(50)};
  }
`

const CarouselOuterContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const SliderContainer = styled.div`
  display: flex;

  @media ${(props) => props.theme.smallUp} {
    margin-left: ${rem(-10)};
    margin-right: ${rem(-10)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding-right: ${rem(20)};
    padding-left: ${rem(20)};
  }

  .carousel-button-back {
    left: 0;
    top: 50%;
    margin-top: ${rem(-40)};

    @media ${(props) => props.theme.smallDown} {
      margin-top: ${rem(-40)};
    }

    @media ${(props) => props.theme.smallDown} {
      margin-top: ${rem(-20)};
    }

    position: absolute;

    &:disabled {
      transform: translateX(-10px);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  }

  .carousel-button-next {
    right: 0;
    top: 50%;
    position: absolute;

    @media ${(props) => props.theme.smallDown} {
      margin-top: ${rem(-40)};
    }

    @media ${(props) => props.theme.smallDown} {
      margin-top: ${rem(-20)};
    }

    &:disabled {
      transform: translateX(10px);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
`

const Publications = (props) => {
  const [tick, setTick] = useState(0)
  const mqMediumDown = useMediaQuery({ query: themeMQ.mediumDown })

  useEffect(() => {
    if (mqMediumDown) {
      setTick((prevTick) => prevTick + 1)
    }
  }, [])

  return (
    <Section>
      {props.publications.map((item, index) => (
        <PublicationsSection key={index}>
          <Container>
            <Row>
              <Col smallUp={12}>
                <Spacer top bottom>
                  <Heading>
                    <span className="styled-label">topic</span>
                    <h3 className="styled-h4">{item.topic}</h3>
                  </Heading>
                </Spacer>
              </Col>
            </Row>
          </Container>

          <Spacer bottom>
            <CarouselOuterContainer>
              <Container>
                <SliderContainer>
                  <Row>
                    <Col smallUp={12}>
                      <Carousel
                        key={tick}
                        /* c8 ignore next */
                        visibleSlides={mqMediumDown ? 1 : 4}
                        slides={item.articles.map((item, index) => (
                          <SinglePublication
                            key={index}
                            {...item}
                            requestArticle={props.requestArticles}
                          />
                        ))}
                      >
                        <ArrowLeftEl />
                        <ArrowRightEl />
                      </Carousel>
                    </Col>
                  </Row>
                </SliderContainer>
              </Container>
            </CarouselOuterContainer>
          </Spacer>
        </PublicationsSection>
      ))}
    </Section>
  )
}

Publications.propTypes = {
  publications: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string,
      articles: PropTypes.array.isRequired,
    })
  ).isRequired,
  requestArticles: PropTypes.bool,
}

export default Publications
