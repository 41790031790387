import React, { useContext, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Hero from "../components/page-publications/hero"
import Publications from "../components/page-publications/publications"

import { contentFromData } from "../utils/publications"

function PublicationsPage() {
  const context = useContext(StoreContext)
  const data = useStaticQuery(graphql`
    {
      allContentfulPublications(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          metaTitle
          metaDescription {
            metaDescription
          }
          keywords
          requestArticles
          title
          topics {
            topic
            articles {
              title
              url
              year
              name
              authors
              upload {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    context.setHeaderTheme(`bright`)
  }, [])

  const { meta, heroBlock, publications, requestArticles } =
    contentFromData(data)

  return (
    <React.Fragment>
      <Meta {...meta} />

      <Hero {...heroBlock} />

      <Publications
        publications={publications}
        requestArticles={requestArticles}
      />
    </React.Fragment>
  )
}

export default PublicationsPage
