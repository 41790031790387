import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import { mailTo } from "../../utils/mailto"

const Container = styled.div`
  padding: 0 ${rem(10)};
  height: 100%;
`

const SinglePublicationEl = styled.div`
  padding: ${rem(30)} ${rem(20)};
  background: ${(props) => props.theme.colorSand};
  text-align: center;
  color: ${(props) => props.theme.colorBlack};
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .styled-label {
    width: 100%;
  }
`

// const StyledLink = styled(Link)`
const StyledLink = styled.a`
  border: 1px solid;
  border-radius: 999em;
  font-size: ${rem(13)};
  line-height: 1.5;
  letter-spacing: 0.175em;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  padding: ${rem(6)} ${rem(25)};
  transition: color 0.3s, background 0.3s;

  &:hover {
    color: ${(props) => props.theme.colorWhite};
    background: ${(props) => props.theme.colorBlack};
  }
`

const Content = styled.p.attrs({ className: `styled-p` })`
  margin-top: ${rem(60)};
  margin-bottom: ${rem(70)};

  span {
    opacity: 0.6;
  }
`

const LinkWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`

const SinglePublication = (props) => (
  <Container data-testid="SinglePublication">
    <SinglePublicationEl>
      <h4
        className="styled-label"
        dangerouslySetInnerHTML={{ __html: props.title }}
      />

      <Content>
        {props.description}
        <br />
        <br />
        <span>{props.name}</span>
      </Content>

      <LinkWrapper>
        {props.requestArticle ? (
          <StyledLink href={mailTo(props.title, props.description)}>
            Request
          </StyledLink>
        ) : (
          <StyledLink href={props.url} target="_blank">
            Read more
          </StyledLink>
        )}
      </LinkWrapper>
    </SinglePublicationEl>
  </Container>
)

SinglePublication.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  requestArticle: PropTypes.bool,
}

export default SinglePublication
