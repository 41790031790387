import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "../container"

const StyledContainer = styled(Container)`
  text-align: center;
  align-self: center;
`

const HeroEl = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colorBlack};
  color: ${(props) => props.theme.colorSand};
`

const Hero = (props) => (
  <HeroEl>
    <StyledContainer>
      <h1 className="styled-h1">{props.title}</h1>
    </StyledContainer>
  </HeroEl>
)

Hero.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.any,
}

export default Hero
