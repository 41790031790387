export function topicFromTopics(
  topic = {
    articles: [],
  }
) {
  return {
    topic: topic.topic,
    articles: topic.articles.length ? topic.articles.map(articleFromTopic) : [],
  }
}

export function articleFromTopic(
  article = {
    authors: ``,
    upload: {
      localFile: {
        publicURL: undefined,
      },
    },
    year: ``,
    name: ``,
  }
) {
  return {
    title: `${article.authors.trim()} (${article.year})<br/>${article.name}`,
    description: article.title,
    year: ``,
    name: ``,
    url: article.upload ? article.upload.localFile.publicURL : article.url,
  }
}

export function contentFromData(
  data = {
    allContentfulPublications: {
      nodes: [],
    },
  }
) {
  const publicationsBlock = data.allContentfulPublications?.nodes ?? []
  const publications = publicationsBlock.length
    ? publicationsBlock[0].topics.map(topicFromTopics)
    : []
  return {
    meta: {
      title: publicationsBlock.length
        ? publicationsBlock[0].metaTitle
        : `Research Contributions | Publications`,
      description: publicationsBlock.length
        ? publicationsBlock[0].metaDescription.metaDescription
        : `Click Therapeutics has contributed to a wide range of research on topics including smoking cessation and major depressive disorder. Check out our clinical data.`,
      keywords: publicationsBlock.length
        ? publicationsBlock[0].keywords
        : `ClickTx, Clickotine, MDD, Depression, Nicotine, NRT, Clinical Trial, Digital Therapeutics, Telehealth, mHealth, healthcare`,
    },
    heroBlock: {
      title: publicationsBlock.length
        ? publicationsBlock[0].title
        : `Publications`,
    },
    publications,
    requestArticles: publicationsBlock.length
      ? publicationsBlock[0].requestArticles
      : false,
  }
}
